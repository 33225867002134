/* eslint-disable react/prop-types */
import React from "react";
import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";

// Images
import ImgLeaf from "../Images/ImgLeaf";

const rotate = keyframes`
  0% {
    transform: rotate(-7.5deg);
  }
  50% {
    transform: rotate(7.5deg);
  }
  100% {
    transform: rotate(-7.5deg);
  }
`;

const Container = styled.section`
  z-index: 1;
  position: absolute;
  pointer-events: none;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 1s;
`;

const leafStyle = `
  display: inline-block;
`;

const LeavesRightInner = ({ propRef }) => {
  const leaves = [
    {
      x: 67.5,
      y: -10,
      rotationStart: 320,
      origin: {
        x: 0,
        y: 125
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 55,
      y: 15,
      rotationStart: 110,
      origin: {
        x: 400,
        y: 100
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      },
      zIndex: 1
    },
    {
      x: 50,
      y: -15,
      rotationStart: 45,
      origin: {
        x: 200,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 55,
      y: 5,
      rotationStart: 190,
      origin: {
        x: 350,
        y: 400
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 70,
      y: 16,
      rotationStart: 290,
      origin: {
        x: 0,
        y: 400
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      },
      zIndex: 2
    },
    {
      x: 70,
      y: 40,
      rotationStart: 340,
      origin: {
        x: 0,
        y: 150
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      },
      zIndex: 4
    },
    {
      x: 72,
      y: 55,
      rotationStart: 170,
      origin: {
        x: 375,
        y: 400
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 54,
      y: 34,
      rotationStart: 310,
      origin: {
        x: 0,
        y: 200
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      },
      zIndex: 1
    },
    {
      x: 45,
      y: 55,
      rotationStart: 140,
      origin: {
        x: 400,
        y: 150
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 68.5,
      y: 75,
      rotationStart: 310,
      origin: {
        x: 0,
        y: 200
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 51.5,
      y: 75,
      rotationStart: 70,
      origin: {
        x: 400,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 56,
      y: 55,
      rotationStart: 110,
      origin: {
        x: 400,
        y: 100
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      },
      zIndex: 5
    }
  ];

  return (
    <Container ref={propRef}>
      {leaves.map(leaf => (
        <div
          key={`${leaf.x}${leaf.y}`}
          css={css`
            ${leafStyle}
            position: absolute;
            pointer-events: auto;
            ${
              typeof leaf.zIndex === "undefined"
                ? "z-index: 3;"
                : `z-index: ${leaf.zIndex};`
            }
            left: ${leaf.x}%;
            top: ${leaf.y}%;
            transform-origin: ${leaf.origin.x}px ${leaf.origin.y}px;
            animation: ${leaf.animation.type} ${leaf.animation.speed}s
              ${leaf.animation.ease} infinite;
          `}
        >
          <ImgLeaf
            cssProp={`
              transform: rotate(${leaf.rotationStart}deg) !important;
            `}
          />
        </div>
      ))}
    </Container>
  );
};

export default LeavesRightInner;
