import React from "react";
import { css } from "@emotion/core";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

// eslint-disable-next-line react/prop-types
export default ({ cssProp }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "features/anana-vestige-single-leaf.png" }
        ) {
          childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        loading="eager"
        css={css`
          ${cssProp}
        `}
        fixed={data.placeholderImage.childImageSharp.fixed}
      />
    )}
  />
);
