/* eslint-disable react/prop-types */
import React from "react";
import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";

// Images
import ImgLeaf from "../Images/ImgLeaf";

const rotate = keyframes`
  0% {
    transform: rotate(-7.5deg);
  }
  50% {
    transform: rotate(7.5deg);
  }
  100% {
    transform: rotate(-7.5deg);
  }
`;

const Container = styled.section`
  z-index: 1;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 1s;
`;

const leafStyle = `
  display: inline-block;
`;

const LeavesLeftInner = ({ propRef }) => {
  const leaves = [
    {
      x: 35,
      y: 75,
      rotationStart: 18,
      origin: {
        x: 150,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 35,
      y: -15,
      rotationStart: 18,
      origin: {
        x: 150,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 22,
      y: -7.5,
      rotationStart: 310,
      origin: {
        x: 0,
        y: 200
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 22,
      y: 12,
      rotationStart: 290,
      origin: {
        x: 0,
        y: 300
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 38,
      y: 10,
      rotationStart: 70,
      origin: {
        x: 350,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 20,
      y: 70,
      rotationStart: 320,
      origin: {
        x: 0,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 15,
      y: 45,
      rotationStart: 350,
      origin: {
        x: 0,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 22,
      y: 30,
      rotationStart: 350,
      origin: {
        x: 0,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 30,
      y: 50,
      rotationStart: 40,
      origin: {
        x: 175,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    },
    {
      x: 38,
      y: 29,
      rotationStart: 0,
      origin: {
        x: 0,
        y: 0
      },
      animation: {
        type: rotate,
        ease: "ease-in-out",
        speed: 6.5
      }
    }
  ];

  return (
    <Container ref={propRef}>
      {leaves.map(leaf => (
        <div
          key={`${leaf.x}${leaf.y}`}
          css={css`
            ${leafStyle}
            position: absolute;
            pointer-events: auto;
            ${
              typeof leaf.zIndex === "undefined"
                ? "z-index: 3;"
                : `z-index: ${leaf.zIndex};`
            }
            left: ${leaf.x}%;
            top: ${leaf.y}%;
            transform-origin: ${leaf.origin.x}px ${leaf.origin.y}px;
            animation: ${leaf.animation.type} ${leaf.animation.speed}s
              ${leaf.animation.ease} infinite;
          `}
        >
          <ImgLeaf
            cssProp={`
              transform: rotate(${leaf.rotationStart}deg) !important;
            `}
          />
        </div>
      ))}
    </Container>
  );
};

export default LeavesLeftInner;
