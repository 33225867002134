import styled from "@emotion/styled";
import _configBase from "../../../configs/_configBase";
import { colors, fonts, mediaQueries } from "../../../configs/_configSite";

export default styled.h1`
  position: relative;
  ${_configBase}
  color: ${colors.darkGray};
  font-family: ${fonts.primary};
  font-weight: 100;
  font-size: 4rem;
  margin-bottom: 30px;
  @media (max-width: ${mediaQueries.md}px) {
    font-size: 3.5rem;
  }
  @media (max-width: ${mediaQueries.sm}px) {
    font-size: 3rem;
  }
  @media (max-width: ${mediaQueries.xxs}px) {
    margin-bottom: 15px;
    font-size: 2rem;
  }
`;
